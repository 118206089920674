<template lang="pug">
div(v-observe-visibility="{ callback: visibilityChanged, throttle: 0, once }")
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';
export default {
  name: 'VisibilityObserver',
  directives: {
    'observe-visibility': ObserveVisibility,
  },
  props: {
    once: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    visibilityChanged (isVisible) {
      if (isVisible) this.$emit('visible');
    },
  },
};
</script>
