<template>
  <div>
    <UIFormItem :title="$t('Access Level')" required>
      <div class="radio-grid">
        <UIRadio
          :title="$t('Admin')"
          :value="localUser.accessLevel === 'admin'"
          @change="toggleSelected('admin')"
          :disabled="me.id === localUser.id"
          :instructions="$t('Gives access to all SpringboardVR features, including inviting and managing users.')"
        ></UIRadio>
        <UIRadio
          :title="$t('Manager')"
          :value="localUser.accessLevel === 'manager'"
          @change="toggleSelected('manager')"
          :disabled="me.id === localUser.id"
          :instructions="$t('Gives access to everything but Billing, Organization Details and Manage Users.')"
        ></UIRadio>
        <UIRadio
          :title="$t('Staff')"
          :value="localUser.accessLevel === 'staff'"
          @change="toggleSelected('staff')"
          :disabled="me.id === localUser.id"
          :instructions="$t('Only gives access to Monitor Stations.')"
        ></UIRadio>
        <UIRadio
          v-show="localUser.accessLevel === 'custom'"
          :title="$t('Custom')"
          :value="localUser.accessLevel === 'custom'"
          :disabled="me.id === localUser.id"
          :instructions="$t('This user currently has custom permissions. Modifying them is not longer supported, you must pick a new Access Level.')"
        ></UIRadio>
      </div>
    </UIFormItem>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIRadio from '@/core/shared/components/ui/UIRadio';

@Component({
  components: {
    UIRadio,
    UIFormItem,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    me: {
      type: Object,
      required: true,
    },
  },
})
export default class UserAccesLevel extends Vue {
  get localUser () {
    return new Proxy(this.user, {
      set: (target, property, value) => {
        this.$emit('edit', property, value);
        return true;
      },
    });
  }

  created () {
    // NOTE(Jack): This just ensures that if there is no access level given, it's defaulted to the lowest level (this is mainly for validation purposes)
    if (!this.user.accessLevel) {
      this.user.accessLevel = 'staff';
    }
  }

  toggleSelected (level) {
    this.localUser.allLocations = level === 'admin';
    this.localUser.accessLevel = level;
  }
}
</script>
<style lang="postcss" scoped>
.radio-grid {
  display: grid;
  grid-row-gap: 1rem;
}
</style>
