<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import UiFormItem from './UIFormItem';

@Component({
  inheritAttrs: false,
  props: {
    value: {},
    instructions: {},
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    ...UiFormItem.options.props,
  },
  components: {
    ValidationProvider,
  },
})
export default class UIRadio extends Vue {

}
</script>

<template>
  <!-- NOTE(Jack): The validation doesn't work very well on radio inputs, since it isn't able to catch all of the inputs as being 'one' form item -->
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <label class="ui-radio f8" :class="{ '-active': value }">
      <input class="input" type="radio" v-bind="$attrs" :value="value" :checked="value" v-on="$listeners" @change="$emit('input', $attrs.value)" />
      <slot><span>{{ title }}</span></slot>
      <span class="instructions">{{ instructions }}</span>
    </label>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<style scoped lang="postcss">
@import "../../styles";
.ui-radio {
  font-family: var(--fontSansSerif);
  & .input {
    margin-right: 1rem;
  }

  & .instructions {
    @apply --f7;
    display: block;
    margin-left: 2rem;
  }
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
