<template>
  <div class="users-route">
    <div class="flex-m justify-end mb5">
      <UIButton :to="{ name: 'user-show', params: { userId: 'new' } }" primary>{{ $t('New User') }}</UIButton>
    </div>
    <UIRowIndexLoader class="user-index-loader" v-if="isLoading" />
    <table class="usertable w-100" v-else>
      <thead class="f-thead fadeIn animated">
        <th class="tl nowrap dtc">{{ $t('Name & Email') }}</th>
        <th class="tl nowrap dn dtc-m">{{ $t('Access Level') }}</th>
        <th
          class="nowrap dn dtc-m location-access tl"
          v-show="showLocations"
        >{{ $t('Location Access') }}</th>
      </thead>
      <User v-for="user in users" :key="user.id" :user="user" :showLocations="showLocations"></User>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import User from './components/User';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  name: 'UsersIndexRoute',
  components: {
    UIButton,
    User,
    UIRowIndexLoader,
  },
})
export default class UsersIndexRoute extends Vue {
  get users () {
    const users = this.$store.getters['entities/User'](this.$store.state.users.userIds);
    if (users.length < 1) return;
    const sortedUsers = [...users];
    return sortedUsers.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }

  get showLocations () {
    return this.$store.getters['entities/Location/all']().length > 1;
  }

  get isLoading () {
    return !this.users;
  }

  async created () {
    this.$store.commit('setTitle', this.$t('Users'));
    await this.$store.dispatch('getUsers');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.users-route {
  @apply --container;
  margin: 3rem 0;
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & .usertable {
    border-collapse: separate;
    border-spacing: var(--borderWidth);
    border: 0;
    & td,
    & th {
      vertical-align: middle;
      position: relative;
      @media (--tablet) {
        padding: 0 2rem;
      }
    }
    & .location-access {
      padding-left: 4rem;
    }
  }
}
</style>
