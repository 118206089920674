<template>
  <div>
    <UIFormItem :title="$t('Location Access')" required>
      <UICheckbox
        v-if="locations.length > 1"
        :title="$t('All')"
        name="allLocations"
        @change="toggleAllSelected(locations, !isSelectAllOn(locations))"
        :value="isSelectAllOn(locations)"
        :disabled="localUser.accessLevel === 'admin' || localUser.accessLevel === 'custom'"
      />
      <UICheckbox
        v-for="location in locations"
        :key="location.id"
        :value="isLocationSelected(location)"
        @change="toggleUserOnLocation(location)"
        class="mt3"
        :title="location.title"
        :disabled="localUser.accessLevel === 'admin' || localUser.accessLevel === 'custom' || locations.length === 1"
      />
    </UIFormItem>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';

@Component({
  components: {
    UICheckbox,
    UIFormItem,
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    user () {
      if (this.user.accessLevel === 'admin' && this.localAccessLevel !== 'admin') {
        this.toggleAllSelected(this.locations, true);
      }
    },
  },
})
export default class UserLocations extends Vue {
  localAccessLevel = '';

  get localUser () {
    this.localAccessLevel = this.user.accessLevel;
    return new Proxy(this.user, {
      set: (target, property, value) => {
        this.$emit('edit', property, value);
        return true;
      },
    });
  }

  isLocationSelected (location) {
    return ~this.getLocationIndexInUser(location);
  }

  getLocationIndexInUser (location) {
    if (this.user.locations) {
      return this.user.locations.findIndex(userLocation => {
        return userLocation.id === location.id;
      });
    }
  }

  isSelectAllOn (locations) {
    if (this.localUser.locations) {
      return locations.every(location => {
        return !!this.localUser.locations.find(userLocation => userLocation.id === location.id);
      });
    }
  }

  toggleAllSelected (locations, enable) {
    let newLocations = [...this.localUser.locations].map(location => {
      return { id: location.id, title: location.title };
    });
    locations.forEach(location => {
      const locationIndex = newLocations.findIndex(newLocation => {
        return newLocation.id === location.id;
      });

      if (enable && locationIndex === -1) {
        newLocations = [
          ...newLocations,
          { id: location.id, title: location.title },
        ];
      }

      if (!enable) {
        newLocations = [
          ...newLocations.slice(0, locationIndex),
          ...newLocations.slice(locationIndex + 1),
        ];
      }
    });
    this.localUser.locations = newLocations;
  }

  toggleUserOnLocation (location) {
    const locationIndex = this.getLocationIndexInUser(location);
    if (~locationIndex) {
      this.localUser.locations = [
        ...this.user.locations.slice(0, locationIndex),
        ...this.user.locations.slice(locationIndex + 1),
      ];
    } else {
      this.localUser.locations = [
        ...this.localUser.locations,
        { id: location.id, title: location.title },
      ];
    }
  }
}
</script>
<style lang="postcss" scoped>

</style>
