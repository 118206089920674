<template>
  <div class="ui-row-index-loader animated fadeIn">
    <UIGradientLoader />
    <UIGradientLoader />
    <UIGradientLoader />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIGradientLoader from './UIGradientLoader';

@Component({
  components: {
    UIGradientLoader,
  },
})
export default class UIRowIndexLoader extends Vue {}
</script>

<style lang="postcss" scoped>
@import '../../styles';

.ui-row-index-loader {
  display: grid;
  grid-gap: var(--spacingSm);
}
</style>
