<template>
  <RouterView></RouterView>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class UsersRoute extends Vue {
}
</script>
