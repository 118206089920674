<template>
  <tr class="user-item-tr f-thead fadeIn animated">
    <template>
      <td class="titlecell">
        <div>
          <h4
            class="f-header nowrap dib truncate slate w-100 username"
          >{{ user.name }}</h4>
          <p><span class="mr2-m">{{ user.email }}</span> <span class="i warning dn dib-m" v-if="!user.validated">{{ $t('Account Pending') }}</span></p>
          <p class="i warning dn-m" v-if="!user.validated">{{ $t('Account Pending') }}</p>
        </div>
      </td>
      <td class="tl w-1 dn dtc-m">
        <h4 class="access-level f-thead">{{ user.accessLevel }}</h4>
      </td>
      <td class="tl dn dtc-m locations" v-show="showLocations">
        <h4 class="f-thead" v-if="user.allLocations">{{ $t('All') }}</h4>
        <h4 class="f-thead" v-for="location in user.locations" :key="location.id" v-else>{{ location.title }}</h4>
      </td>
      <td class="editcell">
        <UIButton
          tiny
          icon="edit"
          class="editbtn ml-auto"
          :to="{ name: 'user-show', params: { userId: user.id } }"
        >{{ $t('Edit') }}</UIButton>
      </td>
    </template>
  </tr>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import VisibilityObserver from '@/core/components/utils/VisibilityObserver';

@Component({
  name: 'User',
  components: {
    UIButton,
    VisibilityObserver,
  },
  props: {
    user: { type: Object },
    showLocations: { type: Boolean },
  },
})
export default class User extends Vue {

}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.user-item-tr {
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & .access-level {
    white-space: nowrap;
  }
  & .warning {
    color: var(--colorErrorFlat);
  }
  border-collapse: separate;
  border-spacing: var(--borderWidth);
  border: 0;
  & td,
  & th {
    vertical-align: middle;
    padding: 12px 2px;
    @media (--tablet) {
      padding: 2rem;
    }
    position: relative;
  }
  & td {
    &:before {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      @apply --panelDropShadow;
      z-index: -2;
      background-color: var(--colorWhite);
    }
    &:after {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      z-index: -1;
      background-color: var(--colorWhite);
    }
    &:first-child:after,
    &:first-child:before {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }
    &:last-child:after,
    &:last-child:before {
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 2 * var(--borderWidth));
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
  & .editcell {
    padding: 0 var(--spacingXS);
    width: 1px;
    white-space: nowrap;
    @media (--tablet) {
      padding: 0 var(--spacingMd);
    }
  }
  & .editbtn {
    display: flex;
  }
  & .titlecell {
    max-width: 13vw;
  }
  & .locations {
    width: 15%;
  }
  & .locations h4 {
    position: relative;

    @media (--tablet) {
      padding-left: 2rem;
      &:after {
        content: "";
        z-index: 5;
        position: absolute;
        width: 100%;
        height: 3.6rem;
        top: -1rem;
        left: -3rem;
        border-left: 1px solid var(--colorGeyser);
        border-width: 0 1px;
      }
    }
  }
}
</style>
