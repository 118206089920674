<template>
  <nav class="breadcrumb-nav f6">
    <ul class="list">
      <li class="item" v-for="(item, i) in items" :key="i">
        <span class="chevron" v-if="i > 0">></span>
        <router-link v-if="item.routerLink" :to="item.routerLink">{{ item.name }}</router-link>
        <span v-else>{{ item.name }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
})
export default class BreadcrumbNav extends Vue {

}
</script>

<style scoped lang="postcss">
@import "../styles";

.breadcrumb-nav {
  color: var(--colorManatee);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.white {
    color: white;
    & .item {
      & a {
        &:hover {
          color: var(--colorCasper);
        }
      }
      &:last-child {
        color: white;
      }
    }
  }

  & .list {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  & .item {
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @media (--tablet) {
      &:first-child {
        display: inline-block;
        overflow: visible;
        white-space: normal;
      }
    }
    & a {
      color: inherit;
      transition: color 0.15s ease-in-out;
      &:hover {
        color: var(--colorFjord);
      }
    }
    &:last-child {
      color: var(--colorFjord);
    }
  }
  & .chevron {
    margin: 0 0.5rem;
    margin-left: 0;
    /* @media (--tablet) {
      margin: 0 1rem;
    } */
  }
}
</style>
