<template>
  <Confirm
    class="show confirm"
    @closeConfirm="close"
    :show="true"
    :prevent-scroll="true"
    :buttons="buttons"
    icon="icon-trash"
    :title="$t('Are you sure you want to delete this user?')"
  >
    <template #content>
      <div class="pb4">
        <p class="mv3 f-body">
          {{ $t("This user won't have access to SpringboardVR anymore.") }}
        </p>
      </div>
    </template>
  </Confirm>
</template>

<script>
import Confirm from '@/core/shared/components/Confirm';

export default {
  props: {

  },
  components: {
    Confirm,
  },
  methods: {
    close () {
      this.$emit('closeConfirm');
    },
  },
  computed: {
    buttons () {
      return [
        {
          name: this.$t('No, cancel'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes, delete'),
          action: () => {
            this.$emit('confirm');
            return Promise.resolve();
          },
        },
      ];
    },
  },
};
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

</style>
