<template>
  <div class="user-show container">
    <div class="showtop">
      <BreadcrumbNav
        :items="[ { name: $t('Admin') }, { name: $t('Manage Users'), routerLink: { name: 'users' } }, { name: newUser ? $t('New User') : $t('Edit User') } ]"
      />
      <UIButton
        tiny
        icon="trash"
        v-if="!loading && !newUser && me.id !== userId"
        @click="openDeleteUserPrompt"
      >{{ $t('Delete User') }}</UIButton>
    </div>
    <ValidationObserver v-slot="{ pristine, valid }" tag="div">
      <ContentBlock :loading="loading" :required="true">
        <div slot="instructions">
          <h5>{{ $t('User Contact & Access Information') }}</h5>
          <i18n
            path="Enter the user’s contact information, the level of access and which location(s) are accessible. To learn more about Level and Location Access, read {article}."
            tag="p"
          >
            <template #article>
              <a
                class="link-style"
                href="https://support.springboardvr.com/hc/en-us/articles/360041782614"
                rel="noopener"
              >{{ $t('User Permissions') }}</a>
            </template>
          </i18n>
        </div>
        <div slot="content">
          <div class="userinfos">
            <UIInput
              maxlength="255"
              class="input name"
              :title="$t('Name')"
              :value="user.name"
              @input="editUser('name', $event.target.value)"
              required
              rules="required|alphaSpacesNumericRich"
            />
            <UIInput
              maxlength="255"
              class="input email"
              :title="$t('Email')"
              :value="user.email"
              @input="editUser('email', $event.target.value)"
              required
              rules="required|email"
            />
            <UserAccessLevel :user="user" :me="me" @edit="editUser" />
            <UserLocations :locations="locations" :user="user" @edit="editUser" />
          </div>
        </div>
      </ContentBlock>
      <DeleteUserModal
        v-show="showDeleteUserPrompt"
        @closeConfirm="closeDeleteUserPrompt"
        @confirm="deleteUser"
      />
      <BottomBar
        :valid="!pristine && valid"
        :actionText="newUser ? $t('Send Invite'): $t('Save')"
        :savingText="newUser ? $t('Sending Invite...'): $t('Saving...')"
        :backRouterLink="{ name: 'user-index' }"
        :saving="saving"
        @save="onSave"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';

import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import BottomBar from '@/core/shared/components/BottomBar';
import BreadcrumbNav from '@/core/shared/components/BreadcrumbNav';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';

import { isEqual } from 'lodash-es';
import UserLocations from './components/UserLocations';
import UserAccessLevel from './components/UserAccessLevel';
import DeleteUserModal from './components/DeleteUserModal';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    BreadcrumbNav,
    BottomBar,
    ContentBlock,
    UIButton,
    UIInput,
    UserLocations,
    UserAccessLevel,
    DeleteUserModal,
    ValidationObserver,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
})
export default class UserShowRoute extends mixins(FocusModeMixin) {
  saving = false;
  loading = false;
  showDeleteUserPrompt = false;
  userChanges = {};
  defaultUser = {
    name: '',
    email: '',
    accessLevel: 'admin',
    allLocations: true,
    locations: [],
  }

  get me () {
    return this.$store.getters.currentUser;
  }

  get newUser () {
    return this.userId === 'new';
  }

  get userInStore () {
    return this.newUser ? this.defaultUser : this.$store.getters['entities/User'](this.userId);
  }

  get user () {
    return { ...this.userInStore, ...this.userChanges };
  }

  get locations () {
    return this.$store.getters['entities/Location/all']();
  }

  editUser (property, value) {
    const dirty = !isEqual(this.userInStore[property], value);
    if (dirty) this.$set(this.userChanges, property, value);
    else this.$delete(this.userChanges, property);
  }

  closeDeleteUserPrompt () {
    this.showDeleteUserPrompt = false;
  }

  openDeleteUserPrompt () {
    this.showDeleteUserPrompt = true;
  }

  onSave () {
    if (this.newUser) this.sendInvite();
    else this.saveChanges();
  }

  async sendInvite () {
    try {
      this.saving = true;
      await this.$store.dispatch('storeNewUser', this.user);

      this.$store.commit('setFlash', {
        message: `${this.$t('An email invite was successfully sent.')}`,
        type: 'success',
      });
      this.$router.push({ name: 'user-index' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('There was an error creating a new user, please try again.'),
      });
    } finally {
      this.saving = false;
    }
  }

  async deleteUser () {
    try {
      this.saving = true;

      await this.$store.dispatch('deleteUser', {
        id: this.userId,
        ...this.userChanges,
      });

      this.$store.commit('setFlash', {
        message: `${this.$t('The user was successfully deleted.')}`,
        type: 'success',
      });
      this.$router.push({ name: 'user-index' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('There was an error deleting the user, please try again.'),
      });
    } finally {
      this.saving = false;
    }
  }

  async saveChanges () {
    // If user is not validated and email is changed, send new invite
    // would this disable the first invite?
    if (!this.user.validated && this.userInStore.email !== this.user.email) {
      this.sendInvite();
    } else {
      try {
        this.saving = true;
        await this.$store.dispatch('storeUser', {
          id: this.userId,
          ...this.userChanges,
        });

        this.$store.commit('setFlash', {
          message: `${this.$t('The changes were successfully saved.')}`,
          type: 'success',
        });
        this.$router.push({ name: 'user-index' });
      } catch (e) {
        this.$store.commit('setFlash', {
          type: 'error',
          message: this.$t('There was an error saving your changes, please try again.'),
        });
      } finally {
        this.saving = false;
      }
    }
  }

  async created () {
    this.loading = true;
    this.$store.commit('setLoading', true);

    if (this.newUser) {
      this.$store.commit('setTitle', this.$t('New User'));
      this.defaultUser.locations = [...this.$store.getters['entities/Location/all']()].map(location => {
        return { id: location.id, title: location.title };
      });
    } else {
      this.$store.commit('setTitle', this.$t('Edit User'));
      await this.$store.dispatch('getUser', { id: this.userId });
    }

    this.$store.commit('setLoading', false);
    this.loading = false;
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.user-show {
  margin-top: var(--spacingMd);

  & .showtop {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: var(--spacingMd);

    & > * {
      flex: 1 1 auto;

      &:last-child {
        flex: 0 0 auto;
        text-align: right;
      }
    }
  }
  & .userinfos {
    display: grid;
    grid-gap: 4rem var(--spacingSm);
    grid-template-columns: 1fr;

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>

<style lang="postcss">
@import "core/shared/styles";

.ui-checkbox {
  margin-left: 2rem;
}

.ui-checkbox:nth-child(2) {
  margin-left: 0;
}
</style>
